<template>
  <div>
    <el-breadcrumb class="Bdh" separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>内容管理</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: topath }">{{ toptitle }}</el-breadcrumb-item>
      <el-breadcrumb-item>{{ title }}</el-breadcrumb-item>
    </el-breadcrumb>
    <el-form style="text-align: left;" label-width="80px">
      <el-form-item label="封面图片">
        <upload :src.sync="fmpic"></upload>
      </el-form-item>
      <!-- <el-form-item label="选择时间" style="text-align: left;">
                <el-date-picker v-model="date" type="datetime" placeholder="选择日期时间" format="yyyy-MM-dd HH:mm:ss"
                value-format="yyyy-MM-dd HH:mm:ss"></el-date-picker>
            </el-form-item> -->
      <!-- <el-form-item label="是否设置为精选推荐" style="text-align: left;">
                <el-radio v-model="radio" label="1">是</el-radio>
                <el-radio v-model="radio" label="0">否</el-radio>
            </el-form-item> -->
      <el-form-item label="排序" v-if="cate == 2">
        <el-input
          v-model="sortnum"
          placeholder="数字越大排名越靠前"
          autocomplete="off"
          style="width: 15%;float: left;"
        >
        </el-input>
      </el-form-item>
      <el-form-item label="文章标题">
        <el-input v-model="articleT" placeholder="请输入标题" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="文章详情"></el-form-item>
      <FullEditor v-model="content" id="rec" rows="10" upload="/"></FullEditor>
      <div style="margin-top: 80px;text-align: center;">
        <el-button @click="cancel">取 消</el-button>
        <el-button type="primary" @click="sub">确 定</el-button>
      </div>
    </el-form>
  </div>
</template>
<script>
import FullEditor from "../components/FullEditor.vue";
import upload from "../components/upload";
export default {
  components: {
    upload,
    FullEditor,
  },
  data() {
    return {
      type: "",
      id: "",
      articleT: "",
      content: "",
      editorOption: {
        placeholder: "编辑文章内容",
      },
      title: "添加文章",
      fmpic: "",
      date: "",
      radio: "0",
      sortnum: "",
      cate: "",
      toptitle: "",
      topath: "",
    };
  },
  mounted() {
    this.type = this.$route.query.type;
    this.id = this.$route.query.id;
    this.cate = this.$route.query.cate;
    if (this.cate == 1) {
      this.toptitle = "今日推荐";
      this.topath = "/recommendToday";
    } else {
      this.toptitle = "精选推荐";
      this.topath = "/selectRecommend";
    }
    if (this.id) {
      //读取详情接口
      this.axios.get("/gu/get_gu_article_by_id?id=" + this.id).then((res) => {
        if (res.data.code == 0) {
          this.articleT = res.data.result.title;
          if (res.data.result.content != "") {
            let txt = this.htmlDecode(res.data.result.content);
            this.$nextTick(() =>
              setTimeout(() => {
                tinymce.activeEditor.setContent(txt);
              }, 300)
            );
          }
          this.fmpic = res.data.result.image;
          this.sortnum = res.data.result.sort;
        } else {
          console.log(res);
        }
      });
    }
    if (this.type != "new") {
      this.title = "编辑文章";
    }
  },
  methods: {
    onEditorChange({ editor, html, text }) {
      this.content = html;
    },
    cancel() {
      // this.$router.push('/articleManage');
      this.$router.go(-1);
    },
    sub() {
      let data = tinyMCE.activeEditor.getContent();
      let str = "",
        url = "";
      let form = {
        title: this.articleT,
        content: this.htmlEncode(data),
        // cate: '1',1：首页推荐；2：精选推荐
        image: this.fmpic,
        // datetime: this.date
        // sort:this.sortnum,
        doc_id:''
      };
      if (this.cate == 1) {
        form.cate = "1";
      } else {
        form.cate = "2";
        form.sort = this.sortnum;
      }
      if (this.type != "new") {
        form.id = this.id;
        str = "修改成功";
        url = "/gu/update_gu_article";
      } else {
        str = "添加成功";
        url = "/gu/insert_gu_article";
      }
      if (form.title == "") {
        this.$message({
          message: "请填写标题",
          type: "error",
        });
        return;
      }
      if (form.content == "") {
        this.$message({
          message: "请填写内容",
          type: "error",
        });
        return;
      }
      if (form.image == "") {
        this.$message({
          message: "请上传封面",
          type: "error",
        });
        return;
      }
    //   console.log(form);
      this.axios.post(url, this.qs.stringify(form))
          .then((res) => {
              if (res.data.code == 0) {
                  this.$message({
                      message: str,
                      type: 'success'
                  });
                  this.$router.push(this.topath);
              } else {
                  this.$message(res.data.msg);
              }
          });
    },
    htmlEncode: function(html) {
      //1.首先动态创建一个容器标签元素，如DIV
      var temp = document.createElement("div");
      //2.然后将要转换的字符串设置为这个元素的innerText或者textContent
      temp.textContent != undefined ? (temp.textContent = html) : (temp.innerText = html);
      //3.最后返回这个元素的innerHTML，即得到经过HTML编码转换的字符串了
      var output = temp.innerHTML;
      temp = null;
      return output;
    },
    /*2.用浏览器内部转换器实现html解码（反转义）*/
    htmlDecode: function(text) {
      //1.首先动态创建一个容器标签元素，如DIV
      var temp = document.createElement("div");
      //2.然后将要转换的字符串设置为这个元素的innerHTML(ie，火狐，google都支持)
      temp.innerHTML = text;
      //3.最后返回这个元素的innerText或者textContent，即得到经过HTML解码的字符串了。
      var output = temp.innerText || temp.textContent;
      temp = null;
      return output;
    },
  },
};
</script>
<style>
.Bdh {
  height: 50px;
  width: 100%;
  line-height: 40px;
}
</style>
